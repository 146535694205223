import { Link } from 'gatsby';
import React from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

export default () => {
  return (
    <div className="container pt-6">
      <h3 className="text-info">
        <IoIosCheckmarkCircleOutline className="icon mr-2" />
        Message sent
      </h3>
      <p>Your message has been sent. We will get back to you as soon as possible.</p>
      <Link className="link-plain" to="/">
        <button className="btn btn-ifu">Back to IFU Home</button>
      </Link>
    </div>
  );
};
